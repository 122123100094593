var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { useSidebar } from '../../../../contexts/SidebarContext';
import PropTypes from "prop-types";
import { Button, Badge, Collapse, ListItem } from "@mui/material";
import ExpandLessTwoToneIcon from "@mui/icons-material/ExpandLessTwoTone";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
var SidebarMenuItem = function (_a) {
    var children = _a.children, link = _a.link, Icon = _a.icon, badge = _a.badge, openParent = _a.open, active = _a.active, name = _a.name, image = _a.image, index = _a.index, rest = __rest(_a, ["children", "link", "icon", "badge", "open", "active", "name", "image", "index"]);
    var _b = useState(openParent), menuToggle = _b[0], setMenuToggle = _b[1];
    var _c = useSidebar(), toggleSidebar = _c.toggleSidebar, handleActiveModule = _c.handleActiveModule, activeModule = _c.activeModule;
    var toggleMenu = function (name) {
        setMenuToggle(function (Open) { return !Open; });
        handleActiveModule(name);
    };
    if (children) {
        return (_jsxs(ListItem, __assign({ component: "div", className: "Mui-children" }, rest, { children: [_jsx(Button, __assign({ className: clsx({ "Mui-active": menuToggle }), startIcon: !!Icon ? _jsx(Icon, {}, void 0) : !!image ? _jsx("img", { src: image, width: "20px", alt: "logo" }, void 0) : '', endIcon: menuToggle ? _jsx(ExpandLessTwoToneIcon, {}, void 0) : _jsx(ExpandMoreTwoToneIcon, {}, void 0), onClick: function () { return toggleMenu(name); } }, { children: name }), void 0), _jsx(Collapse, __assign({ in: menuToggle }, { children: children }), void 0)] }), name));
    }
    return (_jsx(ListItem, __assign({ component: "div" }, rest, { children: _jsxs(Button, __assign({ className: active ? "Mui-active" : "", component: RouterLink, onClick: toggleSidebar, to: link, startIcon: !!Icon && _jsx(Icon, {}, void 0) }, { children: [name, badge && _jsx(Badge, { badgeContent: badge }, void 0)] }), void 0) }), name));
};
SidebarMenuItem.propTypes = {
    children: PropTypes.node,
    active: PropTypes.bool,
    link: PropTypes.string,
    icon: PropTypes.elementType,
    badge: PropTypes.string,
    open: PropTypes.bool,
    name: PropTypes.string.isRequired,
};
SidebarMenuItem.defaultProps = {
    open: false,
    active: false,
};
export default SidebarMenuItem;
