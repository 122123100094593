var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useSidebar } from "../../contexts/SidebarContext";
import Sidebar from "./Sidebar";
import FloatButton from "./Sidebar/FloatButton";
import { useAuth } from "../../contexts/Auth";
import { requestGbcApi } from "../../services/api";
var MainWrapper = styled(Box)(function (_a) {
    var theme = _a.theme;
    return "\n        flex: 1 1 auto;\n        display: flex;\n        height: 100%;\n\n        @media (min-width: " + theme.breakpoints.values.lg + "px) {\n            padding-left: " + theme.sidebar.width + ";\n        }\n";
});
var MainContent = styled(Box)(function (_a) {
    var theme = _a.theme;
    return "\n        margin-top: " + theme.header.height + ";\n        flex: 1 1 auto;\n        overflow: auto;\n";
});
var SidebarLayout = function () {
    var _a = useAuth(), userTokens = _a.userTokens, setDataOfUser = _a.setDataOfUser;
    var sidebarToggle = useSidebar().sidebarToggle;
    useEffect(function () {
        if (userTokens.token && userTokens.tokenDB) {
            (function () {
                return __awaiter(this, void 0, void 0, function () {
                    var response, error_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, requestGbcApi({
                                        method: "GET",
                                        path: "/api/v1/admin/users/me",
                                    })];
                            case 1:
                                response = _a.sent();
                                setDataOfUser(response.data);
                                return [3 /*break*/, 3];
                            case 2:
                                error_1 = _a.sent();
                                console.log(error_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            })();
        }
    }, [userTokens]);
    return (_jsxs(_Fragment, { children: [_jsx(Sidebar, {}, void 0), _jsxs(MainWrapper, __assign({ className: "container " + (sidebarToggle && "sidebar-active") }, { children: [_jsx(MainContent, __assign({ className: "content-container" }, { children: _jsx(Outlet, {}, void 0) }), void 0), _jsx(FloatButton, {}, void 0)] }), void 0)] }, void 0));
};
export default SidebarLayout;
