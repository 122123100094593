var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import App from "./App";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { HelmetProvider } from "react-helmet-async";
import { SnackbarProvider } from "notistack";
import "nprogress/nprogress.css";
import { AppProviders } from "./contexts";
var environment = process.env.NODE_ENV;
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import "react-quill/dist/quill.snow.css";
if (environment !== "production") {
    // MockServer({ environment });
}
ReactDOM.render(_jsx(HelmetProvider, { children: _jsx(AppProviders, { children: _jsx(SnackbarProvider, __assign({ maxSnack: 4 }, { children: _jsx(App, {}, void 0) }), void 0) }, void 0) }, void 0), document.getElementById("root"));
serviceWorker.unregister();
