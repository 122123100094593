var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router";
import SidebarLayout from "../layouts/SidebarLayout";
import BaseLayout from "../layouts/BaseLayout";
import SuspenseLoader from "../components/SuspenseLoader";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import Managements from "../pages/iu/managements";
var Loader = function (Component) { return function (props) {
    return (_jsx(Suspense, __assign({ fallback: _jsx(SuspenseLoader, {}, void 0) }, { children: _jsx(Component, __assign({}, props), void 0) }), void 0));
}; };
// Pages - iu
var Login = Loader(lazy(function () { return import("../pages/login"); }));
var Users = Loader(lazy(function () { return import("../pages/iu/Users"); }));
var Clients = Loader(lazy(function () { return import("../pages/iu/Clients"); }));
var Indicators = Loader(lazy(function () { return import("../pages/iu/Indicators"); }));
var Recharges = Loader(lazy(function () { return import("../pages/iu/Recharges"); }));
// Compliance
var IdVenezuela = Loader(lazy(function () { return import("../pages/compliance/idvenezuela"); }));
var IdPeru = Loader(lazy(function () { return import("../pages/compliance/idperu"); }));
var Ocr = Loader(lazy(function () { return import("../pages/compliance/ocr"); }));
var IdBiometry = Loader(lazy(function () { return import("../pages/compliance/idbiometry"); }));
var ComplienceSanctions = Loader(lazy(function () { return import("../pages/compliance/sanctions"); }));
// System
var SystemMerchants = Loader(lazy(function () { return import("../pages/system/merchants"); }));
var SystemAccounts = Loader(lazy(function () { return import("../pages/system/accounts"); }));
var SystemUsers = Loader(lazy(function () { return import("../pages/system/users"); }));
// Dashboards
var All = Loader(lazy(function () { return import("../pages/dashboards"); }));
export var AppRoutes = function () {
    return (_jsxs(Routes, { children: [_jsx(Route, __assign({ path: "/", element: _jsx(PublicRoute, { children: _jsx(BaseLayout, {}, void 0) }, void 0) }, { children: _jsx(Route, { path: "login", element: _jsx(Login, {}, void 0) }, void 0) }), void 0), _jsxs(Route, __assign({ path: "/", element: _jsx(PrivateRoute, { children: _jsx(SidebarLayout, {}, void 0) }, void 0) }, { children: [_jsx(Route, { index: true, element: _jsx(All, {}, void 0) }, void 0), _jsxs(Route, __assign({ path: "iu" }, { children: [_jsx(Route, { path: "users", element: _jsx(Users, {}, void 0) }, void 0), _jsx(Route, { path: "kyc", element: _jsx(Clients, {}, void 0) }, void 0), _jsx(Route, { path: "cards", element: _jsx(Users, {}, void 0) }, void 0), _jsx(Route, { path: "recharges", element: _jsx(Recharges, {}, void 0) }, void 0), _jsx(Route, { path: "indicators", element: _jsx(Indicators, {}, void 0) }, void 0), _jsx(Route, { path: "management", element: _jsx(Managements, {}, void 0) }, void 0)] }), void 0), _jsxs(Route, __assign({ path: "compliance" }, { children: [_jsx(Route, { path: "ve", element: _jsx(IdVenezuela, {}, void 0) }, void 0), _jsx(Route, { path: "pe", element: _jsx(IdPeru, {}, void 0) }, void 0), _jsx(Route, { path: "ocr", element: _jsx(Ocr, {}, void 0) }, void 0), _jsx(Route, { path: "identifications-biometrics", element: _jsx(IdBiometry, {}, void 0) }, void 0), _jsx(Route, { path: "sanctions", element: _jsx(ComplienceSanctions, {}, void 0) }, void 0)] }), void 0), _jsxs(Route, __assign({ path: "merchants" }, { children: [_jsx(Route, { path: "/merchants", element: _jsx(SystemMerchants, {}, void 0) }, void 0), _jsx(Route, { path: "/merchants/accounts", element: _jsx(SystemAccounts, {}, void 0) }, void 0), _jsx(Route, { path: "/merchants/users", element: _jsx(SystemUsers, {}, void 0) }, void 0)] }), void 0)] }), void 0)] }, void 0));
};
