import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
var BaseLayout = function (_a) {
    var children = _a.children;
    return _jsx(_Fragment, { children: children || _jsx(Outlet, {}, void 0) }, void 0);
};
BaseLayout.propTypes = {
    children: PropTypes.node
};
export default BaseLayout;
