var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Divider, Paper, Stack, Typography, } from "@mui/material";
import { cloneElement, memo, } from "react";
function FormContainer(_a) {
    var title = _a.title, subTitle = _a.subTitle, actions = _a.actions, children = _a.children, _b = _a.mode, mode = _b === void 0 ? "div" : _b, rest = __rest(_a, ["title", "subTitle", "actions", "children", "mode"]);
    return (_jsxs(Paper, __assign({ elevation: 10, component: mode, role: mode, onSubmit: "onSubmit" in rest ? rest.onSubmit : undefined }, rest, { children: [title && (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ component: "header", sx: { p: 2 } }, { children: [_jsx(Typography, __assign({ variant: "h4" }, { children: title }), void 0), subTitle && (_jsx(Typography, __assign({ variant: "subtitle1", sx: { fontSize: 13 } }, { children: subTitle }), void 0))] }), void 0), _jsx(Divider, {}, void 0)] }, void 0)), _jsx(Box, __assign({ sx: { py: 4, px: 2 } }, { children: children }), void 0), actions && (_jsxs(_Fragment, { children: [_jsx(Divider, {}, void 0), _jsx(Box, __assign({ component: "footer", sx: { p: 2 } }, { children: _jsx(Stack, __assign({ direction: "row", spacing: 2, justifyContent: "flex-end" }, { children: actions.map(function (action, i) {
                                return cloneElement(_jsx(_Fragment, { children: action }, void 0), { key: i });
                            }) }), void 0) }), void 0)] }, void 0))] }), void 0));
}
export default memo(FormContainer);
