var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useSidebar } from '../../../contexts/SidebarContext';
import Logo from '../../../assets/img/logo/logo-global-color.png';
import HeaderUserbox from '../Header/Userbox';
import { Box, Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import SidebarMenu from './SidebarMenu';
var SidebarWrapper = styled(Box)(function (_a) {
    var theme = _a.theme;
    return "\n        width: " + theme.sidebar.width + ";\n        color: " + theme.sidebar.textColor + ";\n        background: " + theme.sidebar.background + ";\n        box-shadow: " + theme.sidebar.boxShadow + ";\n        height: 100%;\n        \n        @media (min-width: " + theme.breakpoints.values.lg + "px) {\n            position: fixed;\n            z-index: 10;\n            border-top-right-radius: " + theme.general.borderRadius + ";\n            border-bottom-right-radius: " + theme.general.borderRadius + ";\n        }\n";
});
var TopSection = styled(Box)(function (_a) {
    var theme = _a.theme;
    return "\n        display: flex;\n        height: 88px;\n        align-items: center;\n        margin: 0 " + theme.spacing(2) + " " + theme.spacing(2) + ";\n        border-bottom: " + theme.sidebar.dividerBg + " solid 1px;\n";
});
function Sidebar() {
    var _a = useSidebar(), sidebarToggle = _a.sidebarToggle, toggleSidebar = _a.toggleSidebar;
    var closeSidebar = function () { return toggleSidebar(); };
    return (_jsx(Drawer, __assign({ anchor: "left", open: sidebarToggle, onClose: closeSidebar, variant: "temporary", elevation: 5 }, { children: _jsx(SidebarWrapper, { children: _jsxs(Scrollbars, __assign({ autoHide: true }, { children: [_jsx(TopSection, { children: _jsx("img", { src: Logo, alt: "Core Global Bridge Connections", width: 180 }, void 0) }, void 0), _jsx(SidebarMenu, {}, void 0), _jsx(HeaderUserbox, {}, void 0)] }), void 0) }, void 0) }), void 0));
}
export default Sidebar;
