var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from "react";
import { ThemeProvider } from "@mui/material";
import { themeCreator } from "./base";
import { StylesProvider } from "@mui/styles";
export var ThemeContext = React.createContext(function (themeName) { });
var ThemeProviderWrapper = function (props) {
    var curThemeName = localStorage.getItem("appTheme") || "PureLightTheme";
    var _a = useState(curThemeName), themeName = _a[0], _setThemeName = _a[1];
    var theme = themeCreator(themeName);
    var setThemeName = function (themeName) {
        localStorage.setItem("appTheme", themeName);
        _setThemeName(themeName);
    };
    return (_jsx(StylesProvider, __assign({ injectFirst: true }, { children: _jsx(ThemeContext.Provider, __assign({ value: setThemeName }, { children: _jsx(ThemeProvider, __assign({ theme: theme }, { children: props.children }), void 0) }), void 0) }), void 0));
};
export default ThemeProviderWrapper;
