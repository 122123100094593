var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Container } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
var PageTitle = styled(Box)(function (_a) {
    var theme = _a.theme;
    return "\n        padding: " + theme.spacing(4, 0) + ";\n        background:" + 'white' + ";\n";
});
var PageTitleWrapper = function (_a) {
    var children = _a.children;
    return (_jsx(_Fragment, { children: _jsx(PageTitle, __assign({ className: "page-title", p: [1, 1] }, { children: _jsx(Container, __assign({ maxWidth: "lg" }, { children: children }), void 0) }), void 0) }, void 0));
};
PageTitleWrapper.propTypes = {
    children: PropTypes.node.isRequired
};
export default PageTitleWrapper;
