var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Typography, Divider, Stack, Box, Menu, MenuItem, IconButton, Tooltip, } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { MoreVert, Refresh } from "@mui/icons-material";
import Logo from "../../assets/img/logo/logo-global-color.png";
import PageTitleWrapper from "../PageTitleWrapper";
function PageHeader(_a) {
    var name = _a.name, description = _a.description, actions = _a.actions, onRefresh = _a.onRefresh, isFetching = _a.isFetching, leftComponent = _a.leftComponent;
    var _b = useState(null), anchorElMoreOptions = _b[0], setAnchorElMoreOptions = _b[1];
    var handleCloseMoreOptions = function () {
        setAnchorElMoreOptions(null);
    };
    var open = Boolean(anchorElMoreOptions);
    var handleClickMoreOptions = function (event) {
        setAnchorElMoreOptions(event.currentTarget);
    };
    var handleClickOptions = function (actionButton) {
        actionButton;
        handleCloseMoreOptions();
    };
    return (_jsxs(_Fragment, { children: [_jsx(PageTitleWrapper, { children: _jsxs(Stack, __assign({ direction: "row", alignItems: "center", sx: {
                        py: 1.5,
                    } }, { children: [_jsx(Box, { children: _jsx("img", { src: Logo, alt: "Core Global Bridge Connections", width: 140 }, void 0) }, void 0), _jsxs(Box, __assign({ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }, { children: [_jsxs(Box, __assign({ className: "module-description" }, { children: [_jsx(Typography, __assign({ variant: "h3", component: "h3", gutterBottom: true, marginRight: 1 }, { children: name }), void 0), description && (_jsxs(Typography, __assign({ variant: "subtitle2" }, { children: ["| ", description] }), void 0))] }), void 0), _jsxs(Box, __assign({ sx: {
                                        flex: 1,
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        gap: 2,
                                    } }, { children: [leftComponent && _jsx(_Fragment, { children: leftComponent }, void 0), actions && actions.length > 0 && (_jsx(IconButton, __assign({ id: "demo-positioned-button", "aria-controls": open ? "demo-positioned-menu" : undefined, "aria-haspopup": "true", "aria-expanded": open ? "true" : undefined, onClick: handleClickMoreOptions }, { children: _jsx(MoreVert, { color: "secondary" }, void 0) }), void 0)), onRefresh && (_jsx(Tooltip, __assign({ title: "Refrescar el listado", arrow: true }, { children: _jsx(LoadingButton, __assign({ onClick: onRefresh, loading: isFetching, variant: "outlined", size: "small", sx: { height: 30 }, disabled: isFetching }, { children: _jsx(Refresh, {}, void 0) }), void 0) }), void 0))] }), void 0)] }), void 0)] }), void 0) }, void 0), _jsx(Divider, {}, void 0), _jsx(Menu, __assign({ id: "demo-positioned-menu", "aria-labelledby": "demo-positioned-button", anchorEl: anchorElMoreOptions, open: open, onClose: handleCloseMoreOptions, anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                } }, { children: actions === null || actions === void 0 ? void 0 : actions.map(function (action) { return (_jsx(MenuItem, __assign({ onClick: action.onClick }, { children: action.label }), action.label)); }) }), void 0)] }, void 0));
}
export default PageHeader;
