var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, CircularProgress } from '@mui/material';
function SuspenseLoader() {
    useEffect(function () {
        NProgress.start();
        return function () {
            NProgress.done();
        };
    }, []);
    return (_jsx(Box, __assign({ sx: { position: 'fixed', left: 0, top: 0, width: '100%', height: '100%' }, display: "flex", alignItems: "center", justifyContent: "center" }, { children: _jsx(CircularProgress, { size: 64, disableShrink: true, thickness: 3 }, void 0) }), void 0));
}
export default SuspenseLoader;
