var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import config from "config";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AppRoutes } from "./routes";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useLoadingRequest } from "./contexts/BackdropLoaderContext";
import ThemeProvider from "./theme/ThemeProvider";
import { CssBaseline } from "@mui/material";
import BackdropLoader from "./components/BackdropLoader";
import { queryClient } from "./services/queryClient";
import "./assets/scss/style.scss";
var App = function () {
    var isLoadingRequest = useLoadingRequest().isLoadingRequest;
    useEffect(function () {
        if (isLoadingRequest) {
            document.body.style.overflow = "hidden";
        }
        else {
            document.body.style.overflow = "auto";
        }
    }, [isLoadingRequest]);
    return (_jsxs(QueryClientProvider, __assign({ client: queryClient }, { children: [_jsxs(ThemeProvider, { children: [isLoadingRequest && _jsx(BackdropLoader, {}, void 0), _jsxs(LocalizationProvider, __assign({ dateAdapter: AdapterDateFns }, { children: [_jsx(CssBaseline, {}, void 0), _jsx(BrowserRouter, { children: _jsx(AppRoutes, {}, void 0) }, void 0)] }), void 0)] }, void 0), config.env !== "production" && (_jsx(ReactQueryDevtools, { position: "bottom-right" }, void 0))] }), void 0));
};
export default App;
