var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Container } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PageHeader from "../../components/PageHeader";
import FormContainer from "../../components/Form/FormContainer";
import CustomInputForm from "../../components/Form/CustomInputForm";
var schema = yup
    .object({
    email: yup
        .string()
        .email("Ingrese un correo válido")
        .required("El email es requerido"),
    name: yup.string().required("El nombre es requerido."),
})
    .required();
function Managements() {
    var _a = useForm({
        resolver: yupResolver(schema),
    }), control = _a.control, handleSubmit = _a.handleSubmit, errors = _a.formState.errors;
    var onSendSubmit = function (data) {
        console.log(data);
    };
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, { name: "IU", description: "Configuraci\u00F3n del m\u00F3dulo" }, void 0), _jsx(Container, { children: _jsxs(FormContainer, __assign({ sx: { marginTop: 5 }, mode: "form", onSubmit: handleSubmit(onSendSubmit), title: "Informaci\u00F3n principal", subTitle: "Llenar todos los campos requeridos*", actions: [
                        _jsx(Button, __assign({ variant: "outlined" }, { children: "Cancelar" }), void 0),
                        _jsx(Button, __assign({ type: "submit", variant: "contained" }, { children: "Enviar" }), void 0),
                    ] }, { children: [_jsx(CustomInputForm, { name: "email", control: control, label: "Correo electr\u00F3nico", helperText: "El correo se utilizar\u00E1 para noticias importantes", error: errors.email }, void 0), _jsx(CustomInputForm, { name: "name", control: control, error: errors.name, label: "Apellidos" }, void 0)] }), void 0) }, void 0)] }, void 0));
}
export default Managements;
