var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext } from "react";
import { AUTH_USER_DATA, USER_TOKENS } from "../utils/constants";
import { usePersistedState } from "../hooks/usePersistedState";
import { queryClient } from "../services/queryClient";
var AuthContext = createContext({});
var AuthProvider = function (_a) {
    var children = _a.children;
    var _b = usePersistedState(AUTH_USER_DATA, {}), user = _b[0], setUser = _b[1];
    var _c = usePersistedState(USER_TOKENS, {}), userTokens = _c[0], setUserTokens = _c[1];
    var setTokensForUser = useCallback(function (tokens) {
        setUserTokens(tokens);
    }, []);
    var setDataOfUser = useCallback(function (data) {
        setUser(data);
    }, []);
    var logout = useCallback(function () {
        setUserTokens({});
        setUser({});
        queryClient.clear();
    }, []);
    return (_jsx(AuthContext.Provider, __assign({ value: {
            user: user,
            userTokens: userTokens,
            setTokensForUser: setTokensForUser,
            setDataOfUser: setDataOfUser,
            logout: logout,
        } }, { children: children }), void 0));
};
function useAuth() {
    var context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
}
export { AuthProvider, useAuth };
