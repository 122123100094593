var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, createContext, useContext } from "react";
// eslint-disable-next-line @typescript-eslint/no-redeclare
var SidebarContext = createContext({});
var SidebarProvider = function (_a) {
    var children = _a.children;
    var _b = useState(false), sidebarToggle = _b[0], setSidebarToggle = _b[1];
    var _c = useState(''), activeModule = _c[0], setActiveModule = _c[1];
    var handleActiveModule = function (module) {
        setActiveModule(module);
    };
    var toggleSidebar = function () {
        setSidebarToggle(!sidebarToggle);
    };
    return (_jsx(SidebarContext.Provider, __assign({ value: { sidebarToggle: sidebarToggle, toggleSidebar: toggleSidebar, activeModule: activeModule, handleActiveModule: handleActiveModule } }, { children: children }), void 0));
};
function useSidebar() {
    var context = useContext(SidebarContext);
    if (!context) {
        throw new Error("useSidebar must be used within SidebarProvider");
    }
    return context;
}
export { SidebarProvider, useSidebar };
