import { AutoGraph, AccountCircleTwoTone as AccountCircleTwoToneIcon, Settings, } from "@mui/icons-material";
import iuLogo from "../../../../assets/img/logo/iu.png";
import gbcLogo from "../../../../assets/img/logo/gbc.png";
var usersAccessModules = {
    rol: "gbc_iu_api_admin_operator",
    modules: ["dashboard", "iu", "compliance", "onboarding", "gbc-vale"],
};
var menuItems = [
    {
        heading: "",
        items: [
            {
                key: "dashboard",
                name: "Dashboard",
                link: "/",
                icon: AutoGraph,
                roles: [
                    "gbc_core_merchant_admin",
                    "gbc_core_admin_operaciones",
                    "gbc_core_admin_root",
                ],
            },
        ],
    },
    {
        heading: "Modulos",
        items: [
            {
                key: "iu",
                name: "iu",
                image: iuLogo,
                items: [
                    {
                        name: "Usuarios",
                        link: "/iu/users",
                        roles: [
                            "gbc_core_admin_root",
                            "gbc_core_merchant_admin",
                            "gbc_core_admin_operaciones",
                        ],
                    },
                    {
                        name: "KYC de Clientes",
                        link: "/iu/kyc",
                        roles: [
                            "gbc_core_admin_root",
                            "gbc_core_merchant_admin",
                            "gbc_core_admin_operaciones",
                        ],
                    },
                    {
                        name: "Tarjetas",
                        link: "/iu/cards",
                        roles: [
                            "gbc_core_admin_root",
                            "gbc_core_merchant_admin",
                            "gbc_core_admin_operaciones",
                        ],
                    },
                    {
                        name: "Recargas",
                        link: "/iu/recharges",
                        roles: [
                            "gbc_core_admin_root",
                            "gbc_core_merchant_admin",
                            "gbc_core_admin_operaciones",
                        ],
                    },
                    {
                        name: "Indicadores",
                        link: "/iu/indicators",
                        roles: ["gbc_core_admin_root", "gbc_core_merchant_admin"],
                    },
                    {
                        name: "Configuración del modulo",
                        link: "/iu/management",
                        roles: ["gbc_core_admin_root", "gbc_core_merchant_admin"],
                    },
                ],
            },
            {
                key: "compliance",
                name: "Cumplimiento",
                image: gbcLogo,
                items: [
                    {
                        name: "Identificación Perú",
                        link: "/compliance/pe",
                        roles: [
                            "gbc_core_admin_root",
                            "gbc_core_merchant_admin",
                            "gbc_core_admin_operaciones",
                        ],
                    },
                    {
                        name: "Identificación Venezuela",
                        link: "/compliance/ve",
                        roles: [
                            "gbc_core_admin_root",
                            "gbc_core_merchant_admin",
                            "gbc_core_admin_operaciones",
                        ],
                    },
                    {
                        name: "Sanciones / Peps",
                        link: "/compliance/sanctions",
                        roles: [
                            "gbc_core_admin_root",
                            "gbc_core_merchant_admin",
                            "gbc_core_admin_operaciones",
                        ],
                    },
                    {
                        name: "OCR",
                        link: "/compliance/ocr",
                        roles: [
                            "gbc_core_admin_root",
                            "gbc_core_merchant_admin",
                            "gbc_core_admin_operaciones",
                        ],
                    },
                ],
            },
            {
                key: "onboarding",
                name: "Onboarding",
                image: gbcLogo,
                items: [
                    {
                        name: "Perfiles",
                        link: "/onboarding/profile",
                        roles: [
                            "gbc_core_admin_root",
                            "gbc_core_merchant_admin",
                            "gbc_core_admin_operaciones",
                        ],
                    },
                    {
                        name: "Facturación",
                        link: "/onboarding/invoice",
                        roles: ["gbc_core_admin_root", "gbc_core_merchant_admin"],
                    },
                    {
                        name: "Configuración",
                        link: "/onboarding/managment",
                        roles: ["gbc_core_admin_root", "gbc_core_merchant_admin"],
                    },
                ],
            },
            {
                key: "gbc-vale",
                name: "GBC Vale",
                icon: AccountCircleTwoToneIcon,
                items: [
                    {
                        name: "Clientes",
                        link: "/gbcvale/clients",
                        roles: [
                            "gbc_core_admin_root",
                            "gbc_core_merchant_admin",
                            "gbc_core_admin_operaciones",
                        ],
                    },
                    {
                        name: "Tarjetas",
                        link: "/gbcvale/cards",
                        roles: [
                            "gbc_core_admin_root",
                            "gbc_core_merchant_admin",
                            "gbc_core_admin_operaciones",
                        ],
                    },
                    {
                        name: "Configuración",
                        link: "/gbcvale/managment",
                        roles: ["gbc_core_admin_root", "gbc_core_merchant_admin"],
                    },
                ],
            },
        ],
    },
    {
        heading: "Configuraciones",
        items: [
            {
                key: "system",
                name: "Sistema",
                icon: Settings,
                items: [
                    {
                        name: "Comercios",
                        link: "/merchants",
                        roles: ["gbc_core_admin_root"],
                    },
                    {
                        name: "Cuentas",
                        link: "/merchants/accounts",
                        roles: ["gbc_core_admin_root"],
                    },
                    {
                        name: "Usuarios",
                        link: "/merchants/users",
                        roles: ["gbc_core_admin_root"],
                    },
                ],
            },
        ],
    },
];
export default menuItems;
