import { useState, useEffect } from 'react';
export function usePersistedState(key, initialState) {
    var _a = useState(function () {
        var storageValue = localStorage.getItem(key);
        if (storageValue && storageValue !== undefined) {
            return JSON.parse(storageValue);
        }
        return initialState;
    }), state = _a[0], setState = _a[1];
    useEffect(function () {
        localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
}
