var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { ChevronRight } from '@mui/icons-material';
import { useSidebar } from '../../../../contexts/SidebarContext';
export default function FloatingActionButtonSize() {
    var _a = useSidebar(), sidebarToggle = _a.sidebarToggle, toggleSidebar = _a.toggleSidebar;
    return (_jsx(Box, __assign({ sx: { '& > :not(style)': { m: 1 } }, className: "float-button", onClick: toggleSidebar }, { children: _jsx(Fab, __assign({ color: "secondary", "aria-label": "add" }, { children: _jsx(ChevronRight, {}, void 0) }), void 0) }), void 0));
}
