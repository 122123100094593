var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Avatar, Box, Divider, lighten, Stack, Typography, IconButton, } from "@mui/material";
import { styled } from "@mui/material/styles";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth } from "../../../../contexts/Auth";
var MenuUserBox = styled(Box)(function (_a) {
    var theme = _a.theme;
    return "\n        background: " + theme.colors.alpha.black[5] + ";\n        padding: " + theme.spacing(2) + ";\n";
});
var UserBoxText = styled(Box)(function (_a) {
    var theme = _a.theme;
    return "\n        text-align: left;\n        padding-left: " + theme.spacing(1) + ";\n";
});
var UserBoxLabel = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return "\n        font-weight: " + theme.typography.fontWeightBold + ";\n        color: " + theme.palette.secondary.main + ";\n        display: block;\n";
});
var UserBoxDescription = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return "\n        color: " + lighten(theme.palette.secondary.main, 0.5) + "\n";
});
function HeaderUserbox() {
    var _a;
    var _b = useAuth(), logout = _b.logout, user = _b.user;
    return (_jsxs(_Fragment, { children: [_jsxs(MenuUserBox, __assign({ display: "flex", position: "absolute", bottom: 0, width: "100%" }, { children: [_jsx(Avatar, { variant: "rounded", alt: user.fname, src: user.profile_image_path }, void 0), _jsxs(Stack, __assign({ sx: { width: "100%" }, direction: "row", justifyContent: "space-between" }, { children: [_jsxs(UserBoxText, { children: [_jsxs(UserBoxLabel, __assign({ variant: "body1" }, { children: [user.fname, " ", user.lname] }), void 0), _jsx(UserBoxDescription, __assign({ variant: "body2" }, { children: (_a = user.merchants) === null || _a === void 0 ? void 0 : _a.company_name }), void 0)] }, void 0), _jsx(IconButton, __assign({ color: "secondary", onClick: logout }, { children: _jsx(LogoutIcon, {}, void 0) }), void 0)] }), void 0)] }), void 0), _jsx(Divider, {}, void 0)] }, void 0));
}
export default HeaderUserbox;
