var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import config from "config";
import { USER_TOKENS, AUTH_USER_DATA } from "../utils/constants";
import { queryClient } from "./queryClient";
// import '../services/mocks/api';
var tokens = window.localStorage.getItem(USER_TOKENS);
var isRefreshing = false;
var failedRequestsQueue = [];
function logout() {
    window.localStorage.removeItem(USER_TOKENS);
    window.localStorage.removeItem(AUTH_USER_DATA);
    queryClient.clear();
    window.location.href = "/login";
}
axios.interceptors.response.use(function (response) { return response; }, function (error) {
    if (error.response && error.response.status === 401) {
        logout();
        // TODO: Logic for refresh token
        // tokens = localStorage.getItem(USER_TOKENS);
        // if (!tokens) return logout();
        // const { refreshToken } = JSON.parse(tokens);
        // const originalConfig = error.config as AxiosRequestConfig;
        // if (!isRefreshing) {
        //   isRefreshing = true;
        //   axios
        //     .post('/sessions/refresh-token', {
        //       // TODO: Change endpoint to token refresh
        //       token: refreshToken,
        //     })
        //     .then(response => {
        //       const { refresh_token, token, tokenDB } = response.data;
        //       localStorage.setItem(
        //         USER_TOKENS,
        //         JSON.stringify({ refresh_token, token, tokenDB }),
        //       );
        //       axios.defaults.headers.common[
        //         'authorization'
        //       ] = `Bearer ${token}`;
        //       axios.defaults.headers.common[
        //         'authorizationdb'
        //       ] = `Bearer ${token}`;
        //       failedRequestsQueue.forEach(request =>
        //         request.onSuccess(token, tokenDB),
        //       );
        //       failedRequestsQueue = [];
        //     })
        //     .catch(err => {
        //       failedRequestsQueue.forEach(request => request.onFailure(err));
        //       failedRequestsQueue = [];
        //     })
        //     .finally(() => {
        //       isRefreshing = false;
        //     });
        // }
        // return new Promise((resolve, reject) => {
        //   failedRequestsQueue.push({
        //     onSuccess: (token: string, tokenDB: string) => {
        //       originalConfig.headers['authorization'] = `Bearer ${token}`;
        //       originalConfig.headers['authorizationdb'] = `Bearer ${tokenDB}`;
        //       resolve(axios(originalConfig));
        //     },
        //     onFailure: (err: AxiosError) => {
        //       reject(err);
        //     },
        //   });
        // });
    }
    return Promise.reject(error);
});
export function request(_a) {
    var method = _a.method, domain = _a.domain, path = _a.path, customHeaders = _a.customHeaders, params = _a.params, bodyParams = _a.bodyParams, onUploadProgress = _a.onUploadProgress;
    return __awaiter(this, void 0, void 0, function () {
        var headers, configAxios;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    headers = {
                        "Content-Type": "application/json",
                    };
                    if (!!customHeaders) {
                        Object.assign(headers, customHeaders);
                    }
                    configAxios = {
                        url: "" + domain + path,
                        method: method,
                        headers: headers,
                    };
                    if (method === "GET") {
                        Object.assign(configAxios, {
                            params: params,
                        });
                    }
                    if (method === "POST") {
                        Object.assign(configAxios, {
                            data: bodyParams,
                        });
                    }
                    if (onUploadProgress) {
                        Object.assign(configAxios, { onUploadProgress: onUploadProgress });
                    }
                    return [4 /*yield*/, axios(configAxios).catch(function (e) {
                            throw e;
                        })];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    });
}
export function requestGbcApi(_a) {
    var _b, _c, _d;
    var path = _a.path, method = _a.method, params = _a.params, bodyParams = _a.bodyParams, customHeaders = _a.customHeaders, onUploadProgress = _a.onUploadProgress;
    return __awaiter(this, void 0, Promise, function () {
        var requestParams, headers, tokens, parseToken, response, error_1;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    requestParams = {
                        domain: config.apiUrl,
                        method: method,
                        path: path,
                        params: params,
                        bodyParams: bodyParams,
                        onUploadProgress: onUploadProgress,
                    };
                    headers = {};
                    tokens = localStorage.getItem(USER_TOKENS);
                    parseToken = JSON.parse(tokens || "{}");
                    if (Object.keys(parseToken).length > 0) {
                        headers.authorization = "Bearer " + parseToken.token;
                        headers.authorizationDB = "Bearer " + parseToken.tokenDB;
                    }
                    Object.assign(requestParams, {
                        customHeaders: __assign(__assign({}, headers), customHeaders),
                    });
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, request(requestParams).catch(function (e) {
                            throw e;
                        })];
                case 2:
                    response = _e.sent();
                    return [2 /*return*/, response.data];
                case 3:
                    error_1 = _e.sent();
                    if (!((_b = error_1.response) === null || _b === void 0 ? void 0 : _b.status)) {
                        logout();
                        return [2 /*return*/];
                    }
                    throw __assign({ status: (_c = error_1.response) === null || _c === void 0 ? void 0 : _c.status }, (_d = error_1.response) === null || _d === void 0 ? void 0 : _d.data);
                case 4: return [2 /*return*/];
            }
        });
    });
}
